import React, { useEffect, useState } from 'react';
import {
  Col,
  Divider,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Switch,
  Typography,
} from 'antd';
import { useTranslation } from 'react-i18next';

import { Assets, useUpdateGameMutation } from '@shared/api';

import { AppForm, AssetInput, MultipleLngItem, SubmitButton } from 'components';
import { useLoggedClient } from 'features/auth';
import { useInsertGameByType } from 'hooks/useInsertGameByType';
import { GameData, GameTypeData } from '../types';

const { Title } = Typography;

type FormValues = {
  name: string;
  image?: Assets;
  description?: { [lng: string]: string };
  with_instant_gains: boolean; // eslint-disable-line camelcase
  with_random_drawings: boolean; // eslint-disable-line camelcase
  with_magic_random_drawings: boolean; // eslint-disable-line camelcase
};

interface Props {
  game?: GameData;
  gameType: GameTypeData;
  onAdd: (game: GameData) => void;
}

export const GameFormSetup = ({ game, gameType, onAdd }: Props) => {
  const { t } = useTranslation();
  const { id: clientId, role } = useLoggedClient();
  const [form] = Form.useForm<FormValues>();
  const [loading, setLoading] = useState<boolean>(false);

  const [updateGameMutation] = useUpdateGameMutation();
  const [insertGame] = useInsertGameByType({ onAdd });

  const onSubmit = async (values: FormValues) => {
    setLoading(true);
    const {
      with_magic_random_drawings: withMagicRandomDrawings,
      image,
      ...formatedValues
    } = values;

    const body = {
      ...formatedValues,
      image_id: image?.id,
      with_magic_random_drawings:
        formatedValues.with_random_drawings && withMagicRandomDrawings,
      client_id: !game && role === 'admin' ? clientId : undefined,
      type_id: !game && role === 'admin' ? gameType.id : undefined,
    };
    if (game) {
      await updateGameMutation({
        variables: {
          gameId: game.id,
          game: body,
        },
      });
    } else {
      await insertGame({ gameTypeAcronym: gameType.acronym, game: body });
    }
  };

  const initialValues = {
    name: game?.name,
    image: game?.image,
    description: game?.description,
    max_participations: game?.max_participations || 1,
    frequency: game?.frequency || 'DAILY',
    with_instant_gains: game?.with_instant_gains || false,
    with_random_drawings: game?.with_random_drawings || false,
    with_magic_random_drawings: game?.with_magic_random_drawings || false,
  };

  useEffect(() => {
    form.resetFields();
  }, [game]);

  return (
    <AppForm
      form={form}
      initialValues={initialValues}
      onFinish={values => onSubmit(values).finally(() => setLoading(false))}
    >
      <Form.Item
        label={t('games.form.name')}
        name="name"
        rules={[{ required: true }]}
      >
        <Input placeholder={t('games.form.name')} />
      </Form.Item>
      <Row gutter={16}>
        <Col flex={1}>
          <Form.Item
            name="image"
            label={t('games.form.image')}
            rules={[{ required: true, message: t('games.form.imageRequired') }]}
          >
            <AssetInput withCrop />
          </Form.Item>
        </Col>
      </Row>
      <Divider />
      <MultipleLngItem
        name="description"
        label={t('games.form.description')}
        alreadyUsedLngs={game?.description ? Object.keys(game.description) : []}
      />

      <Title level={5}>{t('games.form.gameSetup')}</Title>
      <Row gutter={16}>
        <Col flex={1}>
          <Form.Item
            label={t('games.form.maxParticipations')}
            name="max_participations"
            rules={[{ required: true }]}
          >
            <InputNumber
              min={1}
              max={1000}
              style={{ width: '100%' }}
              placeholder={t('games.form.maxParticipations')}
            />
          </Form.Item>
        </Col>
        <Col flex={1}>
          <Form.Item
            label={t('games.form.frequency')}
            name="frequency"
            rules={[{ required: true }]}
          >
            <Select>
              <Select.Option key="DAILY" value="DAILY">
                {t('frequency.daily', { context: 'female' })}
              </Select.Option>
              <Select.Option key="WEEKLY" value="WEEKLY">
                {t('frequency.weekly', { context: 'female' })}
              </Select.Option>
              <Select.Option key="MONTHLY" value="MONTHLY">
                {t('frequency.monthly', { context: 'female' })}
              </Select.Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            name="with_instant_gains"
            valuePropName="checked"
            label={t('games.form.withInstantGains')}
            tooltip={t('games.form.withInstantGainsHelp')}
          >
            <Switch />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) =>
              prevValues.with_random_drawings !==
              currentValues.with_random_drawings
            }
          >
            {({ getFieldValue }) => (
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    name="with_random_drawings"
                    valuePropName="checked"
                    label={t('games.form.withRandomDrawings')}
                    tooltip={t('games.form.withRandomDrawingsHelp')}
                  >
                    <Switch />
                  </Form.Item>
                </Col>
                {getFieldValue('with_random_drawings') && (
                  <Col span={12}>
                    <Form.Item
                      name="with_magic_random_drawings"
                      valuePropName="checked"
                      label={t('games.form.withMagicRandomDrawings')}
                      tooltip={t('games.form.withMagicRandomDrawingsHelp')}
                    >
                      <Switch />
                    </Form.Item>
                  </Col>
                )}
              </Row>
            )}
          </Form.Item>
        </Col>
      </Row>
      <Row justify="end">
        <SubmitButton loading={loading} />
      </Row>
    </AppForm>
  );
};
