import React, { useState } from 'react';
import { Switch } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useTranslation } from 'react-i18next';

import {
  useArchiveGameMutation,
  useGetGameTypeByIdQuery,
  useGetGamesQuery,
  useUpdateGameMutation,
} from '@shared/api';

import { AppPageTable, DeleteAction } from 'components';
import { ActionProps } from 'components/AppPageTable';
import { useLoggedClient } from 'features/auth';
import { GameDrawer, Props as DrawerProps } from './GameDrawer';
import { GameData } from './types';

interface GamesProps {
  typeId: string;
}

const Games = ({ typeId }: GamesProps) => {
  const { t } = useTranslation();
  const { id: clientId } = useLoggedClient();
  const [drawerProps, setDrawerProps] = useState<
    Pick<DrawerProps, 'visible' | 'game'>
  >({
    visible: false,
  });
  const { data: dataGameType } = useGetGameTypeByIdQuery({
    variables: { typeId },
  });
  const { data, loading } = useGetGamesQuery({
    variables: {
      where: {
        _and: [
          {
            client_id: { _eq: clientId },
            type_id: { _eq: typeId },
            is_archive: { _eq: false },
          },
        ],
      },
    },
  });
  const [updateGameMutation] = useUpdateGameMutation();
  const [archiveGameMutation] = useArchiveGameMutation({
    update: (cache, { data: archiveData }) => {
      if (archiveData && archiveData.update_games_by_pk) {
        cache.modify({
          fields: {
            games: (refs, { readField }) =>
              refs.filter(
                (ref: any) =>
                  archiveData.update_games_by_pk?.id !== readField('id', ref),
              ),
          },
        });
      }
    },
  });

  React.useEffect(() => {
    if (data && drawerProps) {
      // Keep apollo cache sync through the drawer
      setDrawerProps({
        visible: drawerProps.visible,
        game: data.games.filter(game => game.id === drawerProps?.game?.id)[0],
      });
    }
  }, [data]);

  const columns: ColumnsType<GameData> = [
    {
      title: t('table.name'),
      dataIndex: 'name',
    },
    {
      title: t('table.status'),
      dataIndex: 'is_active',
      width: '10%',
      key: 'is_active',
      render: (isActive, game) => (
        <Switch
          checked={isActive}
          onClick={(_, ev) => {
            ev.stopPropagation();
            updateGameMutation({
              variables: {
                gameId: game.id,
                game: { is_active: !isActive },
              },
              optimisticResponse: {
                __typename: 'mutation_root',
                update_games_by_pk: {
                  ...game,
                  is_active: !isActive,
                  __typename: 'games',
                },
              },
            });
          }}
        />
      ),
    },
    {
      title: '',
      key: 'action',
      width: '10%',
      onCell: () => ({ onClick: ev => ev.stopPropagation() }),
      className: 'cursor-default',
      render: game => (
        <DeleteAction
          onDelete={() => {
            archiveGameMutation({
              variables: { gameId: game.id },
              optimisticResponse: {
                __typename: 'mutation_root',
                update_games_by_pk: {
                  ...game,
                  is_archive: true,
                  __typename: 'games',
                },
              },
            });
          }}
        />
      ),
    },
  ];

  const actions: ActionProps<GameData> = [];

  return (
    <>
      <AppPageTable
        pageTitle={
          dataGameType?.games_types_by_pk?.name
            ? t(
                `${dataGameType?.games_types_by_pk?.name}.menuTitle` as any, // eslint-disable-line @typescript-eslint/no-explicit-any
              )
            : ''
        }
        onAdd={() => {
          setDrawerProps({ visible: true });
        }}
        actions={actions}
        columns={columns}
        dataSource={data?.games}
        loading={loading}
        rowKey="id"
        onRow={game => ({
          onClick: () =>
            setDrawerProps({
              visible: true,
              game,
            }),
        })}
        rowClassName="cursor-pointer"
      />
      {dataGameType && dataGameType.games_types_by_pk ? (
        <GameDrawer
          visible={drawerProps.visible}
          game={drawerProps.game}
          gameType={dataGameType.games_types_by_pk}
          onAdd={game =>
            setDrawerProps({
              visible: true,
              game,
            })
          }
          onClose={() => setDrawerProps({ visible: false })}
        />
      ) : null}
    </>
  );
};

export { Games };
