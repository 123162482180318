import React, { useEffect, useState } from 'react';
import { Button, Col, Divider, Form, Input, Row } from 'antd';
import { SaveOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import { Assets, useUpdateCommunicationMutation } from '@shared/api';

import { AppForm, AssetInput, Loader, MultipleLngItem } from 'components';
import { useLoggedClient } from 'features/auth';
import { useInsertCommunicationByType } from 'hooks/useInsertCommunicationByType';
import { CommunicationData, CommunicationTypeData } from '../types';

type FormValues = {
  name: string;
  description: { [lng: string]: string };
  image: Assets;
};

interface CommunicationsFormDetailsProps {
  communication?: CommunicationData;
  communicationType: CommunicationTypeData;
  onAdd: (communication: CommunicationData) => void;
}

export const CommunicationFormSetup = ({
  communication,
  communicationType,
  onAdd,
}: CommunicationsFormDetailsProps) => {
  const { t } = useTranslation();
  const { id: clientId, role } = useLoggedClient();
  const [form] = Form.useForm<FormValues>();
  const [loading, setLoading] = useState<boolean>(false);

  const [updateCommunicationMutation] = useUpdateCommunicationMutation();
  const [insertCommunication] = useInsertCommunicationByType({
    onAdd,
  });

  const onSubmit = async (values: FormValues) => {
    setLoading(true);
    if (communication) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { image, ...rest } = values;
      await updateCommunicationMutation({
        variables: {
          communicationId: communication.id,
          communication: { ...rest, image_id: values.image?.id },
        },
      });
    } else {
      const { image, ...rest } = values;
      insertCommunication({
        communicationTypeName: communicationType.name,
        communication: {
          ...rest,
          image_id: image?.id,
          client_id: role === 'admin' ? clientId : undefined,
          type_id: role === 'admin' ? communicationType.id : undefined,
        },
      });
    }
  };

  const initialValues = {
    name: communication?.name,
    image: communication?.image,
    description: communication?.description,
  };

  useEffect(() => {
    form.resetFields();
  }, [communication]);

  return (
    <AppForm
      form={form}
      initialValues={initialValues}
      onFinish={values => onSubmit(values).finally(() => setLoading(false))}
    >
      <Form.Item
        label={t('communications.form.name')}
        name="name"
        rules={[{ required: true }]}
      >
        <Input placeholder={t('communications.form.name')} />
      </Form.Item>
      <Row gutter={16}>
        <Col flex={1}>
          <Form.Item
            label={t('communications.form.image')}
            name="image"
            rules={[
              {
                required: true,
                message: t('communications.form.imageRequired'),
              },
            ]}
          >
            <AssetInput withCrop />
          </Form.Item>
        </Col>
      </Row>
      <Divider />
      <MultipleLngItem
        name="description"
        label={t('communications.form.description')}
        alreadyUsedLngs={
          communication?.description
            ? Object.keys(communication.description)
            : []
        }
      />

      <Divider />
      <Row justify="end">
        <Button type="primary" htmlType="submit" icon={<SaveOutlined />}>
          {loading ? <Loader /> : t('common.confirm')}
        </Button>
      </Row>
    </AppForm>
  );
};
