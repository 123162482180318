import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Layout, Menu } from 'antd';
import {
  AppstoreOutlined,
  CloudOutlined,
  FileTextOutlined,
  ShopOutlined,
  TeamOutlined,
  UserOutlined,
  UploadOutlined,
  VideoCameraOutlined,
} from '@ant-design/icons';
import styled from 'styled-components';

import {
  useGetGamesTypesQuery,
  GameTypeFragment,
  useGetCommunicationsTypesQuery,
  CommunicationTypeFragment,
} from '@shared/api';

import { useLoggedClient } from 'features/auth';
import { NavLink } from '../routing';
import { CollapsingLogo } from './CollapsingLogo';
import { HeaderLayout } from './HeaderLayout';

const { SubMenu } = Menu;
const { Content, Sider, Footer } = Layout;

export const AppLayout = ({ children }: ChildrenProps): JSX.Element => {
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const { role } = useLoggedClient();
  const [collapsed, setCollapsed] = useState(false);
  const { data } = useGetGamesTypesQuery();
  const { data: communicationsData } = useGetCommunicationsTypesQuery();

  let selectedMenuKey = pathname.split('/')[2] || 'dashboard';
  if (selectedMenuKey === 'games') {
    selectedMenuKey = pathname.split('/')[3] || selectedMenuKey;
  }
  if (selectedMenuKey === 'communications') {
    selectedMenuKey = pathname.split('/')[3] || selectedMenuKey;
  }

  return (
    <MainLayout>
      <LeftSider
        breakpoint="md"
        collapsible
        collapsedWidth="80"
        collapsed={collapsed}
        onCollapse={setCollapsed}
        width={250}
      >
        <CollapsingLogo height={64} collapsed={collapsed} />
        <Menu theme="dark" mode="inline" selectedKeys={[selectedMenuKey]}>
          <Menu.Item key="dashboard" icon={<UserOutlined />}>
            <NavLink to="/">{t('dashboard.menuTitle')}</NavLink>
          </Menu.Item>
          {(role === 'admin' || role === 'reseller') && (
            <Menu.Item key="clients" icon={<UserOutlined />}>
              <NavLink to="/clients">{t('clientsManagment.menuTitle')}</NavLink>
            </Menu.Item>
          )}
          <Menu.Item key="events" icon={<UserOutlined />}>
            <NavLink to="/events">{t('events.menuTitle')}</NavLink>
          </Menu.Item>
          <SubMenu
            key="communication"
            icon={<AppstoreOutlined />}
            title={t('communications.menuTitle')}
          >
            {communicationsData &&
              communicationsData.communications_types.map(
                ({ id, name }: CommunicationTypeFragment) => (
                  <Menu.Item key={id}>
                    <NavLink to={`/communications/${id}`}>
                      {t(
                        `${name}.menuTitle` as any, // eslint-disable-line @typescript-eslint/no-explicit-any
                      )}
                    </NavLink>
                  </Menu.Item>
                ),
              )}
            <Menu.Item key="communication-marketing">
              <NavLink to="/communication">
                {t('communications.e-marketing.menuTitle')}
              </NavLink>
            </Menu.Item>
          </SubMenu>
          <SubMenu
            key="games"
            icon={<VideoCameraOutlined />}
            title={t('games.menuTitle')}
          >
            {data &&
              data.games_types
                .filter(gt => gt.acronym !== 'SC')
                .map(({ id, name }: GameTypeFragment) => (
                  <Menu.Item key={id}>
                    <NavLink to={`/games/${id}`}>
                      {t(
                        `${name}.menuTitle` as any, // eslint-disable-line @typescript-eslint/no-explicit-any
                      )}
                    </NavLink>
                  </Menu.Item>
                ))}
          </SubMenu>
          <Menu.Item key="locations" icon={<UserOutlined />}>
            <NavLink to="/locations">{t('locations.menuTitle')}</NavLink>
          </Menu.Item>
          {/* <Menu.Item key="estimates" icon={<FileDoneOutlined />}>
            <NavLink to="/estimates">{t('estimates.menuTitle')}</NavLink>
          </Menu.Item> */}
          <Menu.Item key="templates" icon={<FileTextOutlined />}>
            <NavLink to="/templates">{t('templates.menuTitle')}</NavLink>
          </Menu.Item>
          <Menu.Item key="gains" icon={<CloudOutlined />}>
            <NavLink to="/gains">{t('gains.menuTitle')}</NavLink>
          </Menu.Item>
          <Menu.Item key="goodies" icon={<TeamOutlined />}>
            <NavLink to="/goodies">{t('goodies.menuTitle')}</NavLink>
          </Menu.Item>
          <Menu.Item key="statistics" icon={<UploadOutlined />}>
            <NavLink to="/statistics">{t('statistics.menuTitle')}</NavLink>
          </Menu.Item>
          <Menu.Item key="settings" icon={<ShopOutlined />}>
            <NavLink to="/settings">{t('settings')}</NavLink>
          </Menu.Item>
        </Menu>
      </LeftSider>
      <RightLayout className="site-layout">
        <HeaderLayout />
        <MainContent>{children}</MainContent>
        <FooterLayout>{t('copyright')}</FooterLayout>
      </RightLayout>
    </MainLayout>
  );
};

const LeftSider = styled(Sider)`
  overflow: auto;
  height: 100vh;
  z-index: 1;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
`;
const MainLayout = styled(Layout)`
  height: 100vh;
  overflow: hidden;
`;
const RightLayout = styled(Layout)`
  transition: all 0.2s;
`;

const MainContent = styled(Content)`
  margin: 0 1.5rem;
  display: flex;
  flex-direction: column;
`;

const FooterLayout = styled(Footer)`
  text-align: right;
  font-size: 0.6rem;
`;
